/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useMemo, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import { PlusIcon } from "@heroicons/react/solid";
import { useRouter } from "next/dist/client/router";
import Link from "next/link";
import { useCurrentUser } from "~/hooks/useCurrentUser";
import { useWeb3React } from "@web3-react/core";
import { injectedConnector } from "~/connectors";
import { useAppState } from "~/containers/app";
import { chainIdsToRPC } from "~/constants";
import { Notification } from "../notifiction";
import { ERROR_STATE } from "~/containers/notification";
import { removeCookie } from "~/utils/cookie";
import { useLadderContract } from "~/hooks/useLadderContract";

const user = {
  name: "Tom Cook",
  email: "tom@example.com",
  imageUrl: "/assets/cc1683.png",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const CTAs = () => {
  const { account, activate } = useWeb3React();
  const { push } = useRouter();
  const { currentUser } = useCurrentUser();

  const activateBrowserWallet = () => activate(injectedConnector);

  const handleWorkout = () => {
    const getUrl = () => {
      if (currentUser?.membershipStatus !== "onboarding") {
        return "https://apps.apple.com/us/app/ladder-teams-fit-together/id1502936453";
      }
      return "https://offers.joinladder.com/survey?utm_medium=organic&utm_source=website&utm_campaign=ladder-nft&utm_term=ladder-nft-cta&utm_content=homepagesurvey";
    };

    window.open(getUrl(), "_blank");
  };

  return (
    <>
      {currentUser?.id && !!account ? (
        <>
          <button
            onClick={handleWorkout}
            className="w-full px-6 py-2 text-sm text-white uppercase transition-transform rounded-full shadow-lg md:shadow-md md:w-auto bg-vermillion hover:scale-105"
          >
            Workout
          </button>
        </>
      ) : !currentUser?.id ? (
        <div
          style={{
            background:
              "linear-gradient(90deg, #E97B88 0%, #78AAFF 56.25%, #A280ED 100%)",
            boxShadow:
              "0px 0.564654px 1.12931px rgba(0, 0, 0, 0.45), 0px 0.564654px 9.14px rgba(255, 255, 255, 0.7)",
          }}
          className="p-1 transition-transform rounded-full hover:scale-110"
        >
          <button
            onClick={() => push("/auth/apple")}
            className="px-6 py-2 text-sm text-white transition-colors bg-black rounded-full md:px-8 md:text-base hover:bg-gray-900"
          >
            GET STARTED
          </button>
        </div>
      ) : !account ? (
        <div
          style={{
            padding: 3,
            background:
              "linear-gradient(90deg, #E97B88 0%, #78AAFF 56.25%, #A280ED 100%)",
            boxShadow:
              "0px 0.564654px 1.12931px rgba(0, 0, 0, 0.45), 0px 0.564654px 9.14px rgba(255, 255, 255, 0.7)",
          }}
          className="w-full p-1 transition-transform rounded-full md:w-auto hover:scale-105"
        >
          <button
            onClick={activateBrowserWallet}
            className="px-6 py-2 text-sm text-white transition-colors bg-black rounded-full md:px-8 md:text-base hover:bg-gray-900"
          >
            Connect Wallet
          </button>
        </div>
      ) : null}
    </>
  );
};

export default function NavBar() {
  const { account } = useWeb3React();
  const { chainIdIsValid } = useAppState();
  const { pathname } = useRouter();
  const { currentUser } = useCurrentUser();

  const handleLogout = () => {
    removeCookie("token");
    removeCookie("userID");
    window.location.reload();
  };

  const handleCoolcat = () => {
    window.open("/coolcatowner", "_blank");
  };

  return (
    <>
      <Notification
        show={!chainIdIsValid && !!account}
        state={ERROR_STATE}
        title="Wrong network selected"
        details={() => {
          return (
            <p className="mt-1 text-sm text-gray-600">
              Please change your metamask network to{" "}
              <strong>
                {chainIdsToRPC[process.env.NEXT_PUBLIC_CLIENT_CHAIN_ID].name}
              </strong>
            </p>
          );
        }}
      />
      <Disclosure as="nav" className="sticky top-0 z-50 bg-white shadow-md">
        {({ open }) => (
          <>
            <div className="px-4 mx-auto sm:px-6 lg:px-8">
              <div className="flex justify-between h-16">
                <div className="flex">
                  <div className="flex items-center flex-shrink-0 space-x-4">
                    <Link href="/">
                      <a className="">
                        <img
                          className="block w-auto h-6 lg:h-8"
                          src="/assets/logo-black.png"
                          alt="Ladder"
                        />
                      </a>
                    </Link>
                  </div>
                </div>
                <div className="flex items-center">
                  {currentUser?.id ? (
                    <div className="flex items-center mr-2 -ml-2 md:hidden">
                      {/* Mobile menu button */}
                      <Disclosure.Button className="inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:text-gray-600 hover:bg-warm-gray focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XIcon className="block w-6 h-6" aria-hidden="true" />
                        ) : (
                          <MenuIcon
                            className="block w-6 h-6"
                            aria-hidden="true"
                          />
                        )}
                      </Disclosure.Button>
                    </div>
                  ) : null}
                  <div className="hidden space-x-4 md:ml-4 md:flex-shrink-0 md:flex md:items-center">
                    {/* Profile dropdown */}
                    {/* <button className="mr-6 text-sm text-gray-500">
                      What is this?
                    </button> */}
                    {pathname === "/" ? (
                      <div className="items-center justify-center hidden space-x-4 md:flex">
                        {currentUser?.id ? (
                          <Menu as="div" className="relative ml-3">
                            <div className="space-x-4 flex-center">
                              <Link href="/blog">
                                <a className="text-gray-900">What is this?</a>
                              </Link>
                              <Menu.Button className="flex text-sm bg-gray-800 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                <span className="sr-only">Open user menu</span>
                                <img
                                  className="w-8 h-8 rounded-full"
                                  src={user.imageUrl}
                                  alt=""
                                />
                              </Menu.Button>
                            </div>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute right-0 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      onClick={handleCoolcat}
                                      className={classNames(
                                        active ? "bg-gray-100" : "",
                                        "block px-4 py-2 text-sm text-gray-700 w-full rounded text-left"
                                      )}
                                    >
                                      Are you a Cool Cat? 🐱
                                    </button>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      onClick={handleLogout}
                                      className={classNames(
                                        active ? "bg-gray-100" : "",
                                        "block px-4 py-2 text-sm text-gray-700 w-full rounded text-left"
                                      )}
                                    >
                                      logout
                                    </button>
                                  )}
                                </Menu.Item>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        ) : null}
                        <CTAs />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            {currentUser?.id ? (
              <Disclosure.Panel className="md:hidden">
                <div className="pt-4 pb-3 border-t border-gray-100">
                  <div className="flex items-center px-5 sm:px-6">
                    <div className="flex-shrink-0">
                      <img
                        className="w-10 h-10 rounded-full"
                        src={user.imageUrl}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="px-2 mt-3 space-y-1 sm:px-3">
                    <button
                      onClick={handleCoolcat}
                      className="block w-full px-3 py-2 text-base font-medium text-left text-gray-400 rounded-md hover:text-gray-600 hover:bg-warm-gray"
                    >
                      Are you a Cool Cat? 🐱
                    </button>
                    <button
                      onClick={handleLogout}
                      className="block w-full px-3 py-2 text-base font-medium text-left text-gray-400 rounded-md hover:text-gray-600 hover:bg-warm-gray"
                    >
                      logout
                    </button>
                  </div>
                </div>
              </Disclosure.Panel>
            ) : null}
          </>
        )}
      </Disclosure>
    </>
  );
}
