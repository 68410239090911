import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import Link from "next/link";
import { useState } from "react";
import { isMobile } from "~/utils/helpers";

export default function Blog() {
  const [instructionsOpen, setInstructionsOpen] = useState(false);
  return (
    <div className="max-w-3xl px-4 py-10 mx-auto sm:px-6 sm:py-12 lg:max-w-4xl lg:py-12 lg:px-8 xl:max-w-6xl">
      <div className="space-y-10 sm:space-y-12 lg:space-y-20 xl:space-y-24">
        <article className="mx-auto prose md:prose-lg lg:prose-xl">
          <figure>
            <img
              src="/assets/blog-banner.png"
              alt="Ladder Proof of Workout NFTs"
            />
            <figcaption className="flex items-center">
              <span className="mr-2">By Joshua Ledbetter</span>
              <Link href="https://rainbow.me/generalkenobi.eth">
                <a target="_blank" className="p-2" rel="noreferer">
                  <img
                    className="w-5"
                    style={{ margin: 0 }}
                    src="/assets/external/rainbow-logo-sm.png"
                  />
                </a>
              </Link>
              <Link href="https://twitter.com/OldObiBenKenobi">
                <a target="_blank" className="p-2" rel="noreferer">
                  <img
                    className="w-6"
                    style={{ margin: 0 }}
                    src="/assets/external/twitter-logo.png"
                  />
                </a>
              </Link>
            </figcaption>
          </figure>
          <div className="flex space-x-2">
            <img
              style={{ margin: 0 }}
              src="/assets/badges/0.png"
              className="h-12 w-11"
            />
            <img
              style={{ margin: 0 }}
              src="/assets/badges/1.png"
              className="w-12 h-12"
            />
            <img
              style={{ margin: 0 }}
              src="/assets/badges/2.png"
              className="w-12 h-12"
            />
            <img
              style={{ margin: 0 }}
              src="/assets/badges/3.png"
              className="w-12 h-12"
            />
          </div>
          <p>
            Welcome Cool Cats and NFT enthusiasts! We’ve been watching the NFT
            space closely for the last few months, and we think it’s time a
            fitness company dives in. We want to join the community of creators,
            collectors, and artists pushing the space forward and help everyone
            live happier and healthier lives. We’re excited to tell you about
            our new NFT, but first, a little about us:
          </p>

          <hr />

          <p>
            We are Ladder, a fitness app for people serious about strength
            training. With Ladder, you get a complete strength-training program
            from elite coaches and a team you can count on. Just download the
            app, choose your Team, and work out whenever/wherever you want, with
            the help of movement videos and audio cues from our expert coaches.
          </p>

          <p>
            We here at Ladder love what’s happening in the NFT community. We’re
            big believers in NFTs’ potential to create a better experience for
            our members. This brings us to the our NFT: Proof of Workout by
            Ladder.
          </p>

          <h4>THE NFT YOU SWEAT FOR</h4>
          <img src="/assets/showroom.png" alt="Ladder NFT Showroom" />
          <p>
            We see endless potential for how the fitness industry can use NFTs.
            With personalized badges and awards from your coach and metadata
            unique to your workouts. We see a chance to create a new standard
            for the fitness industry using Proof of Workout. We want to work on
            this out in the open and get as many people involved as possible.
            But for right now, we wanted to start small: for every workout
            completed, you can claim your badge as an NFT. Consider this our
            introduction to the community, an experiment to see what this could
            become.
          </p>
          <p>
            And as a small gesture of goodwill, we’re giving the Cool Cats
            community 1 Free Month of Ladder when you sign up. Mostly just cause{" "}
            <strong>#welovethecats!</strong>
          </p>
          <img
            src="/assets/external/coolcats-banner.png"
            alt="welovethecats"
            className="rounded-lg"
          />
          <div className="px-4 py-2 rounded-lg lg:px-6 lg:py-4 bg-warm-gray">
            <button
              onClick={() => setInstructionsOpen((current) => !current)}
              className="flex items-center justify-between w-full text-left"
            >
              <div className="flex flex-col">
                <span className="text-lg font-bold uppercase">
                  How to claim your Proof Of Workout:
                </span>
                <span className="text-gray-500">Step by step instructions</span>
              </div>
              <div>
                {instructionsOpen ? (
                  <ChevronUpIcon className="w-8 h-8 text-gray-500" />
                ) : (
                  <ChevronDownIcon className="w-8 h-8 text-gray-500" />
                )}
              </div>
            </button>
            {instructionsOpen ? (
              <div className="pt-4 mt-8 border-t border-gray-300">
                <ol>
                  <li>
                    <strong>Downloaded the app and Finish a Workout</strong>
                    <p>
                      After you have{" "}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://apps.apple.com/us/app/ladder-teams-fit-together/id1502936453"
                      >
                        downloaded the app
                      </a>{" "}
                      and completed your first workout, come back to this site
                      and follow the instructions below.
                    </p>
                  </li>
                  <li>
                    <strong>Install Metamask and create a wallet.</strong>
                    <p>
                      The first thing you&apos;ll want to do is make sure you
                      have{" "}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={
                          isMobile()
                            ? "https://apps.apple.com/us/app/metamask/id1438144202?_branch_match_id=960591515419173975"
                            : "https://metamask.io/"
                        }
                      >
                        MetaMask
                      </a>{" "}
                      installed.
                    </p>
                    <blockquote>
                      <p>
                        MetaMask provides the simplest yet most secure way to
                        connect to blockchain-based applications.
                      </p>
                    </blockquote>
                    <p>You&apos;ll need Metamask to claim your NFTs.</p>
                  </li>
                  <li>
                    <strong>Claim Your NFTs!</strong>
                    <img
                      src="/assets/ladder-metaverse-ss.png"
                      className="rounded-lg shadow-lg"
                    />
                    <p>
                      After you are set up, come back here and click{" "}
                      <strong>GET STARTED</strong> above, and follow the steps
                      to log in. Once you authenticate with Apple (so we know
                      which workouts you have completed) you&apos;ll connect to
                      Metamask and then be given the option to claim your NFTs!
                      You will also be asked if you are a member of the Cool Cat
                      community. If you are, you&apos;ll be sent to a new page
                      where we&apos;ll check your wallet and give you a coupon
                      code for 30 days free!
                    </p>
                    <p>
                      <strong>
                        You do not need any ETH or MATIC in order to claim.
                      </strong>{" "}
                      We take care of that for you and send the NFT directly to
                      your wallet. After this, you can jump over to{" "}
                      <a
                        href="https://opensea.io/collection/joinladder"
                        target="_blank"
                        rel="noreferrer"
                      >
                        OpenSea
                      </a>{" "}
                      or your favorite Polygon compatible Wallet to view your
                      new NFTs.
                    </p>
                  </li>
                </ol>
              </div>
            ) : null}
          </div>
          <h4>THANK YOU</h4>
          <p>
            Thanks for checking out our little experiment and come say hey in
            the app. We’ve got quite the community ourselves, and want to be
            there to support you on your journey to a fitter you.
          </p>
          <p>Let’s get those strong hands stronger.</p>
        </article>
      </div>
    </div>
  );
}
